import React, { useEffect, useState} from "react";
import { useEthers, shortenAddress } from "@usedapp/core";
import Web3Model from 'web3modal';
import { providerOptions } from "./providers";

export const Web3ModelButton = () => {
    const { account, activate, deactivate } = useEthers()
    const [activateError, setActivateError] = useState('')
    const { error } = useEthers()

    useEffect(() => {
      if (error && account) {
        setActivateError('Switch to BSC Mainnet')
        return
      }
      setActivateError('')
    }, [error, account])

    const activateProvider = async () => {

      const web3Modal = new Web3Model({
          providerOptions,
        })
        try {
          const provider = await web3Modal.connect()
          await activate(provider)
          setActivateError('')
        } catch (error) {
          setActivateError('error')
        }
    }

    return (
        <div>
            {
              account && <div><button type="button" onClick={deactivate} className="cmn-btn">
              ({ shortenAddress(account) }) - Disconnect 
              </button><p>{activateError}</p></div>
            }
            {
              !account && <div><button type="button" onClick={activateProvider} className="cmn-btn">
              Connect Wallet... 
              </button></div>
            }
        </div>
    )
}
