import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import { DAppProvider, BSC } from '@usedapp/core';

const config  = {
  readOnlyChainId: BSC.chainId,
  readOnlyUrls: {
    [BSC.chainId]: 'https://compatible-damp-wind.bsc.discover.quiknode.pro/a43393992ba5b31cf2454cadc11e537582a188b8'
  },
  refresh: 30,
  notifications: {
    checkIntervel: 1000
  }
};

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);