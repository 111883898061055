import React from 'react'
import { Link } from 'react-router-dom'


function Defi() {
  return (
    <div>
      <section id="banner-section" className="inner-banner games tournaments tourn-details">
        <div className="banner-content d-flex align-items-center">
            <img src="images/tour-right-illu.png" className="banner-right-illu" alt="  "/>
            <div className="container">
                <img src="images/tour-left-banner.png" className="banner-left" alt="  "/>
                <img src="images/tour-right-banner.png" className="banner-right" alt="  "/>

                <div className="row d-flex justify-content-start">
                    <div className="col-lg-12">
                        <div className="text-area">
                            <h1>Defi</h1>
                            <div className="breadcrumb-area">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb d-flex">
                                        <li className="breadcrumb-item"><Link
                                    to="/"
                                    >
                                    Home
                                    </Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Info</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center wi-100">
                        <div className="col-lg-10 text-center">
                            <div className="bottom-area">
                                <h5>Defi</h5>
                                <h3>Daily Rewards</h3>
                                <div className="prize-area">
                                    <h6>Game Profits</h6>
                                    <div className="prize d-flex justify-content-center">
                                        <img src="images/prize-icon.png" alt="  "/>
                                        <h5>100 %</h5>
                                    </div>
                                </div>
                                <div className="bet-details d-flex justify-content-between">
                                    <div className="left">
                                        <h6>Minimum Stake</h6>
                                        <h5>1 BNB</h5>
                                    </div>
                                    <div className="mid">
                                        <h6>Minimum Stakeing Period</h6>
                                        <h5>7 Days</h5>
                                    </div>
                                    <div className="left">
                                        <h6>Type</h6>
                                        <h5>Stake</h5>
                                    </div>
                                </div>
                                <div className="draw-counter">
                                    <div className="date-area d-flex justify-content-center" data-countdown="2022/12/31">
                                    </div>
                                    <div className="time-parameter">
                                    <button type="button" className="cmn-btn">
                                    <Link
                                    to="/defiApp"
                                    >
                                    Stake Now
                                    </Link>
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section id="feature-section" className="game-2 tourn-details">
        <div className="overlay pt-120 pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-item d-flex">
                            <div className="left-side">
                                <div className="img-area">
                                    <img src="images/feature-icon-4.png" alt="  "/>
                                </div>
                            </div>
                            <div className="text-area">
                                <h5>Decentralized</h5>
                                <p>Complete Decentralized Games and Staking Programes!</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-item d-flex">
                            <div className="left-side">
                                <div className="img-area">
                                    <img src="images/feature-icon-5.png" alt="  "/>
                                </div>
                            </div>
                            <div className="text-area">
                                <h5>Safety</h5>
                                <p>Confirmed safety and absolutely fair. You are always protected!</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-item d-flex">
                            <div className="left-side">
                                <div className="img-area">
                                    <img src="images/feature-icon-6.png" alt="  "/>
                                </div>
                            </div>
                            <div className="text-area">
                                <h5>Profitability</h5>
                                <p>Take advantage of our unique staking program! Take part in the rewards!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default Defi