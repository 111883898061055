
import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div id="footer-section">
        <div className="overlay">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="footer-top pt-120 pb-120">
                            <div className="side-contant">
                                <div className="left">
                                    <img className="bg" src='images/footer-left-bg.png' alt="leftbg"/>
                                    <img className="bg circle" src='images/footer-left-circle.png' alt="leftcircle"/>
                                    <img className="top" src='images/footer-left-top.png' alt="lefttop"/>
                                </div>
                                <div className="right">
                                    <img src='images/footer-right.png' alt="right"/>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-6 col-md-6 col-sm-10">
                                    <div className="section-header text-center">
                                        <h4 className="title">Want to Join our Community</h4>
                                        <a href="https://discord.gg/pyw28FKXME">
                                            <button className="cmn-btn">JOIN - Discord</button>
                                        </a>
                                    </div>               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-area pt-120">
                    <div className="row d-flex header-area">
                        <div className="col-lg-8 col-md-8 d-flex justify-content-md-between justify-content-center align-items-center">
                            <div className="logo-section">
                                <a className="site-logo site-title" href="index.html">
                                    <img src='images/logo.png' alt="site-logo"/>
                                </a>
                            </div>
                            <ul className="navbar-nav main-menu d-flex align-items-center">
                                <li>
                                    <Link
                                    to="/"
                                    >
                                    Home
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                    to="/oneDigiLotto"
                                    >
                                    1DLotto
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                    to="/threeDigiLotto"
                                    >
                                    3DLotto
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                    to="/defiApp"
                                    >
                                    Defi
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-4 d-flex align-items-center justify-content-center justify-content-md-end">
                            <div className="social-area d-flex">
                            <a href="https://twitter.com/3dloto"><img src="images/twitter.png" height='20px' alt="twitter"/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-bottom">
            <div className="container">
                <div className="main-content">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8 col-md-8 col-sm-12 d-flex justify-content-start align-items-center justify-cen order-pro">
                            <div className="left-area">
                                <p>Copyright © 3DLotto.org | Designed by -
                                    3DLotto Organization ALL RIGHTS RESERVED
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  )
}

export default Footer