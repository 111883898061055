import React from 'react'
import { Link } from 'react-router-dom'
import { FaGripHorizontal, FaSortDown } from "react-icons/fa"
import { Web3ModelButton } from './components/web3modelbutton'

function Header() {
    
  return (
    <div id="header-section">
        <div className="overlay">
            <div className="container">
                <div className="row d-flex header-area">
                    <div className="logo-section flex-grow-1 d-flex align-items-center">
                        <a className="site-logo site-title" href="https://3dlotto.org">
                            <img src='images/logo.png' alt="site-logo"/></a>
                    </div>
                    <button className="navbar-toggler ml-auto collapsed" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                            
                        <FaGripHorizontal color='white'/>
                    </button>
                    <nav className="navbar navbar-expand-lg p-0">
                        <div className="navbar-collapse collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav main-menu ml-auto">
                                <li className="menu_has_child">
                                    <Link
                                    to="/"
                                    >
                                    Home
                                    </Link>
                                </li>
                                <li className="menu_has_children">
                                    <a href="#0">Lottery <FaSortDown color='white'/></a>
                                    
                                    <ul className="sub-menu">
                                        <li>
                                            <Link
                                            to="/oneDigiLotto"
                                            >
                                            1DLotto
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                            to="/threeDigiLotto"
                                            >
                                            3DLotto
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="menu_has_children">
                                    <a href="#0">Defi <FaSortDown color='white'/></a>
                                    
                                    <ul className="sub-menu">
                                        <li>
                                            <Link
                                            to="/defi"
                                            >
                                            Info
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                            to="/defiApp"
                                            >
                                            App
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="menu_has_children">
                                    <a href="#0">Info <FaSortDown color='white'/></a>
                                    
                                    <ul className="sub-menu">
                                        <li>
                                            <Link
                                            to="/about"
                                            >
                                            About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                            to="/winners"
                                            >
                                            Winners
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                            to="/faq"
                                            >
                                            Faq
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <div className="right-area header-action d-flex align-items-center">
                                <Web3ModelButton />         
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Header