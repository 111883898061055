import React, { useState } from 'react'
import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { useEthers, useCall, useEtherBalance, useContractFunction } from '@usedapp/core'
import ThreeDigiLottoAbi from './contracts/threeDigiLotto'
import Countdown from 'react-countdown'
import { parseEther } from '@ethersproject/units/lib'

const contractAddress = '0xc1739f5a5eDC2095371617C1edbeeD91E74ae841';
const contractInterface = new utils.Interface(ThreeDigiLottoAbi); 
const _contract = new Contract(contractAddress, contractInterface);


function ThreeNumberLotto() {
    const [_valueA, setValueA] = useState();
    const [_valueB, setValueB] = useState();
    const [_valueC, setValueC] = useState();
    const [_valueBnb, setValueBnb] = useState('');
    const { account, library } = useEthers();
    const signer = library.getSigner();
    

    const bnbBalance = useEtherBalance(account);

    const { value: _drawNumber } = useCall({
        contract: _contract,
        method: 'Draw',
        args:[]
    }) ?? {}
    const drawNumber = String(_drawNumber)

    const { value: _ticketNumber } = useCall({
        contract: _contract,
        method: 'TicketNumber',
        args:[]
    }) ?? {}
    const ticketNumber = String(_ticketNumber)

    const { value: _winningNumber } = useCall({
        contract: _contract,
        method: 'viewWonNumber',
        args:[drawNumber - 1]
    }) ?? {}
    let wonNumber = [String(_winningNumber)];

    const { value: _drawEnds } = useCall({
        contract: _contract,
        method: 'drawEnds',
        args:[]
    }) ?? {}
    const drawEnds = _drawEnds
    const Completionist = () => {
        const [_value, setValue] = useState();
        const {state, send} = useContractFunction(_contract, 'generateResult', {
            transactionName: '3DLotto-Bet',
            signer: signer
        })
        const { status } = state
        const spinResult = () => {
            send(_value)
        } 

        return (
            <div className="bottom-area wi-100">
                <h5 className='mb-2'>Generate Winning Number</h5>
                <h6 className='mb-2'>Get 50% BetAmount as Reward of your Ticket Number</h6>
                <div className='input-area mb-2'>
                    <input type="number" placeholder='Enter Your Ticket Number' width='25px' value={_value} onChange={(e) => setValue(e.target.value)} />
                </div>
                <h5 className="mb-2">Status : {status}</h5>
                <button onClick={() => spinResult()} type="button"  className="cmn-btn mb-4">
                Spin Now
                </button>
            </div>
        )
        
    };
    const DrawCounter = () => {
        return (
            <div>
                <div className='mb-4'>
                    <h5>Draw Ends - </h5>
                        <Countdown date={drawEnds * 1000}>
                            <Completionist />
                        </Countdown>
                </div>
            </div>
        )
    }

    const { value: _minBet } = useCall({
        contract: _contract,
        method: 'minimumBet',
        args:[]
    }) ?? {}
    const minBetResult = _minBet / 10 ** 18;
    const minBet = minBetResult

    const { value: _maxBet } = useCall({
        contract: _contract,
        method: 'maximumBet',
        args:[]
    }) ?? {}
    const maxBetResult = _maxBet / 10 ** 18;
    const maxBet = maxBetResult

    const {state, send} = useContractFunction(_contract, 'buyTicket', {
        transactionName: '3DLotto-Bet',
        signer: signer
    })
    const { status } = state
    const lottoBet = () => {
        send(_valueA, _valueB, _valueC, parseEther(_valueBnb), {value: parseEther(_valueBnb)})
    }

  return (
    <div>
        <section id="latest-activites-section">
            <div className="ani-div">
                <img className="obj-1" src="images/star.png" alt="  "/>
                <img className="obj-2" src="images/star.png" alt="  "/>
                <img className="obj-3" src="images/star-2.png" alt="  "/>
            </div>
            <div className="overlay pt-120">
                <div className="container wow fadeInUp">
                    <div className="row justify-content-between">
                        <div className="col-lg-6 col-md-6">
                            <div className="section-header">
                                <h2 className="title ">Three Digit Lotto</h2>
                                <h5>Contract Address : <a href='https://bscscan.com/address/0xa2a307f6ad42ba8aea6ad735fb3e21b4a96d36f3#code' > { contractAddress } </a></h5>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 d-flex align-items-center justify-content-end justify-cen">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="bets-tab" data-toggle="tab" href="#bets" role="tab"
                                        aria-controls="bets" aria-selected="true">Game</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="myAccount-tab" data-toggle="tab" href="#myAccount" role="tab"
                                        aria-controls="myAccount" aria-selected="true">My Account</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active games tournaments tourn-details" id="bets" role="tabpanel" aria-labelledby="bets-tab">
                                    <div className="table-responsive">
                                        <div className="row d-flex justify-content-center wi-100">
                                            <div className="col-lg-10 text-center">
                                                <div className="bottom-area">
                                                    <h3 className='mb-2'>Draw Number : {drawNumber}</h3>
                                                    <DrawCounter />
                                                    <h6 className='mb-2'>New Ticket Number : {ticketNumber} </h6>
                                                    <div className="prize d-flex justify-content-center">
                                                        <div className='input-area mr-2'>
                                                        <input type="number" value={_valueA} onChange={(e) => setValueA(e.target.value)} min="0" max="9" />
                                                        <h6>A</h6>
                                                        </div>
                                                        <div className='input-area mr-2'>
                                                        <input type="number" value={_valueB} onChange={(e) => setValueB(e.target.value)} min="0" max="9" />
                                                        <h6>B</h6>
                                                        </div>
                                                        <div className='input-area mr-2'>
                                                        <input type="number" value={_valueC} onChange={(e) => setValueC(e.target.value)} min="0" max="9" />                                                  
                                                        <h6>C</h6>
                                                        </div>
                                                    </div>
                                                    <h6 className='mt-2'> Choose Between 0 to 9 in A | B | C</h6>                                              
                                                    
                                                    <h5 className='mt-2 mb-4'>Min Bet : {minBet} BNB / Max Bet : {maxBet} BNB </h5>

                                                    <div className="prize d-flex justify-content-center mt-4">
                                                    <div className="slidecontainer">
                                                        <input type="range" step='0.005' min={minBet} max={maxBet} value={_valueBnb} onChange={(e) => setValueBnb(e.target.value)} className="slider" id="myRange" /><h5>{_valueBnb} - BNB</h5>
                                                    </div>
                                                    </div>
                                                    
                                                    <h5 className='mt-2 mb-4'>Your Balance - {String(bnbBalance / 10 ** 18)} / BNB</h5>
                                                    <h6 className='mb-2'>Status : {status}</h6>
                                                    <button onClick={() => lottoBet()} type="button"  className="cmn-btn mb-4">
                                                    Bet Now
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade games tournaments tourn-details" id="myAccount" role="tabpanel" aria-labelledby="myAccount-tab">
                                    <div className="table-responsive">
                                        <div className="row d-flex justify-content-center wi-100">
                                            <div className="col-lg-10 text-center">
                                            <div className="bottom-area">
                                                <h3 className='mb-4'>View My Bets</h3>
                                                <div className="prize d-flex justify-content-center">
                                                    <div className='input-area'>
                                                    <input type="number mb-2" placeholder='Enter Draw Number' />
                                                    </div>
                                                    <div className='input-area mb-2'>
                                                    <input type="number" placeholder='Enter Ticket' />
                                                    </div>                                  
                                                </div>
                                                <button type="button"  className="cmn-btn mb-4">
                                                Bet Now
                                                </button>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="feature-section" className="game-2 80-120">
            <div className="overlay pt-100 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="single-item d-flex justify-content-center">
                                <div className="text-area">
                                    <h5>View Winning Numbers</h5>
                                    <div className='input-area mb-2'>
                                    <input type="number mb-2" placeholder='Enter Draw Number' />
                                    </div>
                                    <button type="button"   className="cmn-btn mb-4">
                                    View Won Number
                                    </button>
                                    <h5>Number : </h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="single-item d-flex">
                                
                                <div className="text-area justify-content-center">
                                    <h5>Last Winning</h5>
                                    <h5>Draw Number : {drawNumber - 1}</h5>
                                    <h5>Won Number : {wonNumber}</h5>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="single-item d-flex">
                                <div className="text-area justify-content-center">
                                    <h5>Prize</h5>
                                    <h5>100 X for 3 number Win</h5>
                                    <h5>20 X for last 2 number Win</h5>
                                    <h5>3 X for last 1 number Win</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default ThreeNumberLotto;